<template>
    <div>
        <vx-card :title="$t('Question.questionmgmt')" class="mb-base">
            <!-- 新增預約通知 按鈕 -->
            <vs-divider class="my-0" color="black"></vs-divider>
            <vs-row class="list">
                <vs-col v-if="total != 0">
                    <vs-list>
                        <vs-list-item :title="item.title" :subtitle="$t('Question.writer') + '：' + item.name" v-for="(item, index) in questionData" :key="index">
                            <vs-row>
                                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                                    <div class="text-right" style="margin-right: 1em">
                                        <!-- 建立時間 -->
                                        <p class="cd-date text-base" v-if="windowWidth >= 768">{{ $t('schedule.setTime') }}: {{ item.create_at }}</p>
                                    </div>
                                    <vs-button class="cd-button-2w" @click="showDetail(item)">{{ $t('Question.answer_member') }}</vs-button>
                                </vs-col>
                            </vs-row>
                        </vs-list-item>
                    </vs-list>
                </vs-col>
                <vs-col class="mt-10" vs-type="flex" vs-justify="center" v-else>
                    <span class="justify-content-between">{{ $t('schedule.noData') }}!</span>
                </vs-col>
                <vs-col class="mt-10" vs-type="flex" vs-justify="center">
                    <vs-pagination :max="windowWidth <= 600 ? 7 : 9" :total="Math.ceil(total / limit)" v-model="page" v-if="total != 0"></vs-pagination>
                </vs-col>
            </vs-row>
        </vx-card>
        <!-- 問卷調查 視窗 -->
        <vs-popup fullscreen :title="title" :active.sync="popupQuestion" id="questionpopup">
            <div class="text-center">
                <iframe @load="load" class="questiondata-iframe" width="98%" :src="questionurl" id="questionframe" frameborder="0"></iframe>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import { getQuestionMembers } from '@/api/user'
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState(['windowWidth', 'partner_id']),
    },
    mounted() {
        this.getQuestionMembers()
    },
    data() {
        return {
            questionData: [],
            total: 0,
            questionurl: '',
            popupQuestion: false,
            title: '',
            page: 1,
            limit: 20,
        }
    },
    watch: {
        page() {
            this.getQuestionMembers()
        },
    },
    methods: {
        load() {
            this.$vs.loading.close()
            var popupid = document.getElementById('questionpopup')
            var iframeid = document.getElementById('questionframe')
            if (iframeid && popupid) {
                iframeid.height = popupid.clientHeight - 140
            }
        },
        async getQuestionMembers() {
            const payload = {
                partner_id: this.partner_id,
                page: this.page,
                limit: this.limit,
            }
            const res = await getQuestionMembers(payload)
            this.questionData = res.data.data.data
            this.total = res.data.data.total
        },
        showDetail(item) {
            this.title = item.title
            this.questionurl = item.url
            this.popupQuestion = true
        },
    },
}
</script>
